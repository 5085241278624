<template>
    <div class="order-wrapper">
        <div class="top-box">
            <span>我的订单</span>
        </div>
        <el-table :data="orderList" style="width: 100%" height="426px">
            <el-table-column prop="OrderNo" label="订单编号"></el-table-column>
            <el-table-column prop="PayTime" label="下单时间"></el-table-column>
            <el-table-column prop="PurchaseDays" label="会员套餐"></el-table-column>
            <el-table-column prop="TotalAmount" label="支付金额"></el-table-column>
            <el-table-column prop="State" label="订单状态">
                <template slot-scope="scope">
                    <div v-if="scope.row.State == 0">已支付</div>
                    <div v-if="scope.row.State == 1">待支付</div>
                    <div v-if="scope.row.State == 2">支付失败</div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
  
<script>
import { GetOrderList } from "@/api";
import { mapGetters } from "vuex";

export default {
    name: "Order",
    computed: {
        ...mapGetters(["isLogin", "userInfo", "netBarId", "netBarName"])
    },
    data() {
        return {
            orderList: [],
            num: '',
        };
    },
    mounted() {
        this._GetOrderList();
    },
    methods: {
        // 获取游戏列表
        async _GetOrderList() {
            let params = {
                consumerId: this.userInfo && this.userInfo.ConsumerId,
                pageIndex: 1,
                pageSize: 999
            };
            let res = await GetOrderList(params);
            if (res.StatusCode == 200) {
                this.orderList = res.Items;
            }
        },
    }
};
</script>
  
<style lang="scss" scoped>
.order-wrapper {
    height: 470px;
    // overflow: auto;
}

.top-box {
    height: 32px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    @include font_col3();

    span {
        position: relative;
        padding-left: 10px;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 15px;
            width: 3px;
            background: $red_col;
        }
    }
}
</style>